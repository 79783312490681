<template>
    <div class="box-bck" v-show="handlerPhraseShow">
      <div class="box-ctrl-mn">
          <div class="box-ctrl-mn-ct">
              <div class="box-context">
                <span>Frase:</span>
                <div class="box-context-item">
                  <textarea cols="35" rows="8" v-html="handlerPhraseText" v-model="handlerPhraseText"></textarea>
                </div>
                <span>Nota:</span>
                <div class="box-context-item">
                  <input type="number" v-model="handlerPhrasePoint"/>
                </div>
              </div> 
              <div class="box-context-bottom">
                <input type="button" v-on:click=" RemovePhrase()" v-show="button_del" value="Deletar" />
                <input type="button" v-model="button_save" v-on:click="HandlerPhraseSave()" />
                <input type="button" v-on:click="HandlerPhrasewindowClose()" value="Cancelar"/>
              </div>  
          </div>
      </div>
    </div>
    <div class="container">
     <div class="container-center">
      <div class="ctn-control">
        <div class="ctn-control-line">
          <div class="box-add">
            <button v-on:click="Update()">Atualizar</button>
            <button v-on:click="HandlerPhrasewindowOpen();">Adicionar frase</button>
          </div>
          <div class="box-score">
            <span>Disponivel:</span>
            <h3>{{ scoreAvailable }}</h3>
          </div>
          <div class="box-score">
            <span>pontos:</span>
            <h3>{{ scoreCurrent }}</h3>
          </div>
        </div>
      </div>
      <div class="ctn-list">
        <ul class="phrase-items"> 
          <li v-for="(item, index) in sortedPhases" v-bind:key="item" v-on:click="HandlerPhrasewindowOpen(index);" :class="[item.phrase_item_evaluated ? 'item_evaluated' : 'item_notevaluated']">
            <div>
              <span v-show= "item.phrase_item_evaluated">{{ item.phrase_item_approved ? 'Aprovado' : 'Reprovado' }}</span>
              <h3>{{ item.phrase_item_text }}</h3>
              <span>{{ this.TextPhraseItemNote(item.phrase_item_created_datetime) }} </span>
            </div>
          </li>
        </ul>
      </div>
     </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MainPhrase',
    mounted() {
        this.ScoreUpdate(),
        this.PhraseListUpdate()
    },
    methods:{
        Update(){
          this.ScoreUpdate();
          this.PhraseListUpdate();
        },
        HandlerPhrasewindowOpen: function(index){
            if(index == null){
                this.handlerPhraseId = null;
                this.handlerPhraseText = "";

                this.button_save = "Adicionar";
                this.button_del = false;
            }else{
                var items = this.sortedPhases; 
                this.handlerPhraseId = items[index]['phrases_item_uuid'];
                this.handlerPhraseText = items[index]['phrase_item_text'];
                this.handlerPhrasePoint = items[index]['phrase_item_points'];  

                this. button_save = "Atualizar";
                this.button_del = true;
            }
            this.handlerPhraseShow = true; 
        },
        HandlerPhrasewindowClose: function(){
            this.handlerPhraseShow = false; 
        },
        HandlerPhraseSave: function(){
            if(this.handlerPhraseId == null){
              this.AddPhrase()
            }else{
              this.UpdatePhrase()
            }
        },
        async PhraseListUpdate(){
          this.error = null;
          try {
            const response = await fetch(this.addressApi + "/getPrhasesList");
            if (response.ok) {
              this.phrases_items = await response.json();
            }
          } catch (error) {
            this.error = error.message;
          } finally {
            this.loading = false;
          }
        },
        async AddPhrase(){
            const data = {
                PhraseText: this.handlerPhraseText,
                Points: this.handlerPhrasePoint
            };
            fetch(this.addressApi + '/writePhrase', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(response => {
                if(response){
                    this.HandlerPhrasewindowClose();
                    this.ScoreUpdate();
                    this.PhraseListUpdate();
                }
            }).catch(err => {
                console.error(err);
            })
        },
        async UpdatePhrase(){
            const data = {
              PhraseId: this.handlerPhraseId,
              PhraseText: this.handlerPhraseText,
              Points: this.handlerPhrasePoint
            };
            fetch(this.addressApi + '/updatePhrase', {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(response => {
                if(response){
                    this.HandlerPhrasewindowClose();
                    this.ScoreUpdate();
                    this.PhraseListUpdate();
                }
            }).catch(err => {
                console.error(err);
            })
        },
        async RemovePhrase(){
            fetch(this.addressApi + '/deletePhrase/'+ this.handlerPhraseId, {
                method: "DELETE"
            }).then(response => {
                if(response){
                    this.HandlerPhrasewindowClose();
                    this.ScoreUpdate();
                    this.PhraseListUpdate();
                }
            }).catch(err => {
                console.error(err);
            })
        },
        async ScoreUpdate(){
          this.loading = true;
          this.error = null;
          try {
            const response = await fetch(this.addressApi + "/getscore");
            if (response.ok) {
              this.data = await response.json();
              this.scoreAvailable = this.data.scoreAvailable;
              this.scoreCurrent = this.data.scoreCurrent;
            }
          } catch (error) {
            this.error = error.message;
          } finally {
            this.loading = false;
          }
        },
        TextPhraseItemNote(datetime){
          const dt = new Date(datetime).toLocaleString();

          return dt;
        }
    },
    computed: {
      sortedPhases() {
        return this.phrases_items.slice().sort((a, b) => {
          return new Date(b.phrase_item_created_datetime) - new Date(a.phrase_item_created_datetime);
        });
      }    
    },
    data(){
      return {
        button_del: true,
        button_save: "",
        addressApi: "https://sm.server.rapiger.com",
        scoreAvailable: 0,
        scoreCurrent: 0,
        handlerPhraseShow: false,
        handlerPhraseId: "",
        handlerPhraseText: "",
        handlerPhrasePoint: 10,
        phrases_items: []
      }
    }
  }
  </script>
  
  <style>
    .container{
      height: 100%;
      background-color: #dcdcdc;
      position: relative;
    }
    .container-center{
      margin: 0 auto;
      max-width: 800px;
      background-color: #cac4c4;
    }
    .ctn-control{
      margin: auto;
    }
    .ctn-control-line{
      padding: 10px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .box-add{
      display: flex;
    }
    .box-add button{
      min-width: 100px;
      height: 30px;
      margin: 0 10px 0 0;
    }
    .box-score{
      padding: 10px 15px 10px 10px;
    }
    .box-score span{
      font-size: 9pt;
    }
    .box-score h3{
      text-align: right;
      margin: 0;
      font-size: 20pt;
      font-weight: 700;
    }
    .ctn-list{
      height: 100%;
      padding: 10px;
      overflow-y: hidden;
      overflow-x: hidden;
    }
    .phrase-items{
      margin: 0;
      padding: 5px;
    }
    .phrase-items li{
      margin: 10px 0;
      padding: 10px;
      list-style: none;
      cursor:pointer;
    }
    .phrase-items li div h3 {
      font-size: 10pt;
    }
    .phrase-items li div span {
      font-size: 8pt;
    }
    
    .item_evaluated{
      background-color: #a0a0a0;
    }
    .item_notevaluated{
      background-color: #ffffff;
    }

    /****/
    .box-bck{
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.65);
          z-index: 1000;
      }
      .box-ctrl-mn{
          margin: auto;
          height: 100%;
          max-width: 500px;
          width: auto;
      }
      .box-ctrl-mn-ct{
        border-radius: 5px;
          background-color: #ffffff;
          padding: 10px;
          margin: 100px 0 0 0;
          min-height: 100px;
      }
      .box-context{
        padding: 5px;
      }
      .box-context-item{
        padding: 5px;
        display: flex;
      }
      .box-context span{
        font-size: 10pt;
        padding: 2px 2px 4px 5px;
      }
      .box-context-item textarea{
        width: 100%;
      }
      .box-context-bottom{
        padding: 0 10px 10px;
        display: flex;
        justify-content: flex-end;
      }
      .box-context-bottom input[type=button]{
        min-width: 100px;
        height: 30px;
        margin: 0 0 0 5px;
      }
  </style> 