<template>
  <MainView />
</template>

<script>
import MainView from './views/main.view.vue'

export default {
  name: 'App',
  components: {
    MainView
  }
}
</script>

<style>
body{
  margin: 0;
}
html, body, #app{
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
